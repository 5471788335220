import React from 'react'

import { Button } from '@chakra-ui/react'
import Link from '@components/ui/Link'

interface Props {
    text: string
    link?: string
    fontSize?: string
}

const OutlinedButton = ({ text, link, ...rest }: Props) => (
    <Button w="100%" variant="outline" _hover={{ bg: 'green.500', color: 'white' }} {...rest}>
        {link ? (
            <Link to={link} fontSize="xm" px="2.5" style={{ textDecoration: 'none' }}>
                {text}
            </Link>
        ) : (
            text
        )}
    </Button>
)

export default OutlinedButton
